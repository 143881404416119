import { IconBaseProps } from 'react-icons';

interface Menu {
  id?: number;
  name: string;
  labelPt: string;
  labelEng: string;
  icon: React.ComponentType<IconBaseProps> | null;
  component: string | null;
  path: string | null;
  parent: number | null;
}

function createMenu(data: Menu[]) : any {
  const firstLevel = data.filter((item: any) => !item.parent);
  const tree = firstLevel.map(buildTree);
  return tree;
  function buildTree(item: any) {
    let newList = { ...item };
    const children = data.filter((child: any) => child.parent === newList.id);
    if (children.length > 0) {
      newList.items = [];
      children.map(buildTree).forEach((child: any) => {
        newList.items = [...newList.items, child];
      });
    }
    const userSession = JSON.parse(localStorage.getItem('@theagenda:user')!);

    if (newList.name === 'listar_agendamentos' && userSession.role !== 'ADMIN'){
      newList = {}
    }
    return newList;
  }
}

export default createMenu;