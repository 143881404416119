import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .sizeInput{
        width: 20rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    .span {
        font-size: x-large;
        font-family: sans-serif; 
        color: #a68c5b;
    }
    .buttonStyle {
        width: 10rem;
    }
    .cardStyle {
      width: 300px;
      height: 150px;
      text-align: left;
      background-color: #373435;
      cursor: pointer;
    }
    .noschedules {
      width: 300px;
      height: 150px;
      text-align: center;
      background-color: #373435;
      cursor: pointer;
    }
    .tagp {
      margin-top: -8px;
      font-family: sansSerif; 
      font-size: 20px;
      color: #a68c5b;
    }
    .tagp-noschedules {
      font-family: sansSerif; 
      font-size: 30px;
      color: #a68c5b;
    }
    h1 {
      text-align: center;
      color: #a68c5b;
      bottom: 2;
    }
    #date {
      text-align: center;
    }
    span {
      color: #a68c5b;
    }
    .ficolor {
      color: #a68c5b;
    }
}
`;

const apearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  max-height: 72vh;


  animation: ${apearFromLeft} 1s;

`;