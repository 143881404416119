import React from 'react';
import { Route, Routes, BrowserRouter  } from 'react-router-dom';

import Template from '../components/Template';
import NoMatch from '../pages/NoMatch';
import Home from '../pages/Home';
import Layout from '../Layout';
import Schedule from '../pages/Schedule';
import ScheduleCreate from '../pages/Schedule/Create';
import FilterSchedule from '../pages/Schedule/FilterSchedule';

const AppRoutes: React.FC = () => (
  <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true}}>
  <Template/>
    <Routes>
      <Route path='/' element={<Layout/>}>
        <Route path='/' element={<Home/>} />
        <Route path='schedule' element={<Schedule/>} />
        <Route path='schedule'>
          <Route path='create' element={<ScheduleCreate />} />
          <Route path='filterschedule' element={<FilterSchedule />} />
        </Route>
        <Route path='*' element={<NoMatch/>} /> 
      </Route> 
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;