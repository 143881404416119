import BurgerButton from '../BurgerButton';
import LogOut from '../LogOut';

import { Container } from './styles';

const Header: React.FC = () => (
  <Container>
    <BurgerButton color="#fff" size={30} />
    <LogOut />
  </Container>
);

export default Header;