import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content:center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
  color: #a68c5b;
`;

const apearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation: ${apearFromLeft} 1s;

  form {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      width: 15rem;
      .stylelink {
        margin-top: 13px;
      }
    }
    @media only screen and (min-width: 485px) and (max-width: 769px) {
      width: 25rem;
      height: 60vh;
      .stylelink {
        margin-top: 4px;
      }
      h1 {
      margin: 0px -8px !important;
    }
    }
    @media only screen and (min-width: 772px) and (max-width: 1025px) {
      width: 40rem;
    }
    @media only screen and (min-width: 1030px) and (max-width: 1200px) {
      width: 50rem;
    }
    @media only screen and (min-width: 1205px) {
      width: 60rem;
    }
    margin: 80px;
    width: 340px;
    text-align: center;
    h1 {
      margin-bottom: 10px;
    }

    a {
      color: #a68c5b;
      display: block;
      margin-top: 5px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#F4EDE8')};
      }
    }
    button {
      color: #a68c5b;
      margin-top: 15px;
    }
  }
`;