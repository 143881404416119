import React from 'react';
import { List } from '@mui/material';
import { IconBaseProps } from 'react-icons';
import SidebarItem from './SideBarItem';
import buildTree from './menu';
import menu from '../../utils/menu';

interface ItemData {
  id?: number;
  name: string;
  labelPt: string;
  labelEng: string,
  icon: React.ComponentType<IconBaseProps> | null;
  component: string | null;
  path: string | null;
  parent: number | null;
  items: ItemData;
}

const SidebarContent = () => {
  const depthStep = 10;
  const depth = 0;
  const sidebarItems = buildTree(menu);
  return (
    <List disablePadding dense>
      {
        sidebarItems.map((sidebarItem: ItemData) => (
          <React.Fragment key={`${sidebarItem.name}`}>
            <SidebarItem
              depthStep={depthStep}
              depth={depth}
              item={sidebarItem}
            />
          </React.Fragment>
        ))
      }
    </List>
  );
};

export default SidebarContent;