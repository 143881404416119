import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 25px;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  border-radius: 3px;
`;