import React, { useState } from 'react';
import * as Yup from 'yup';

import {
  Container,
  Content,
  AnimationContainer
} from './styles';
import Loading from '../../../components/Loading';
import { Form, FormikProvider, useFormik } from 'formik';
import { FiLock, FiMail, FiSave } from 'react-icons/fi';
import { UseToast } from '../../../context/Toast';
import { UseLanguage } from '../../../context/Lang';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidadeErros';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import logoImg from '../../../assets/logostudio.jpeg';
import { Divider } from 'antd';


const ChangePassword: React.FC = () => {
  const { addToast } = UseToast();
  const [loading, setLoading] = useState(false);
  const { t } = UseLanguage();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
        username: Yup.string().required('Campo obrigatório.'),
        password: Yup.string().required('Campo obrigatório.')
    }),
    async onSubmit(values, {resetForm}) {
      setLoading(true);
      try {
        formik.setErrors({});
        await api.post('/v1/user/change-password',{
          username: values.username,
          password: values.password
        })
        addToast({
          type: 'success',
          title: 'Senha Alterada com sucesso...',
          description: '',
        });
        navigate('/')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formik.setErrors(errors);
          setTimeout(() => {
            setLoading(false);
            resetForm();
          }, 1000 * 2);
        }
        console.log(err);
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Opss... email incorreto.',
        });
      }
      setLoading(false);
    },
  })

  return (
    <Container>
      <Loading display={loading} />
      <Content>
        <AnimationContainer>
        <img src={logoImg} alt="studio-logo" width={225} height={150} />
        <h1 style={{ color: '#a68c5b' }}>{t('welcome')}</h1>
        <h2 style={{ color: '#a68c5b' }}>{t('create.password')}</h2>
        <Divider />
        <FormikProvider 
          value={formik}
          >
          <Form style={{ margin: 15 }} 
           onSubmit={formik.handleSubmit}>
              <span style={{ color: '#a68c5b', fontSize: 20 }}>Confirme o e-mail cadastrado</span>
              <Input 
                name="username" 
                icon={FiMail} 
                placeholder={t('email')} 
                value={formik.values.username}
                onChange={formik.handleChange}
              />
              <span style={{ color: '#a68c5b',fontSize: 20}}>Crie a nova senha</span>
              <Input 
                name="password" 
                icon={FiLock} 
                placeholder={t('password')} 
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              <Button type="submit" $bgcolor="#373435" $color="#ffffff">
                <FiSave style={{ color: '#a68c5b' }}/>
                <span style={{ color: '#a68c5b' }}>{t('update')}</span>
              </Button>
            </Form>
          </FormikProvider>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default ChangePassword;