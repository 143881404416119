const dataCard = (value:number, cardencrypted: string) => {
    const data = {
    "reference_id": "ex-00001",
    "customer": {
        "name": "Studio Nice Hair",
        "email": "limanice91@usopag.com.br",
        "tax_id": "41596635000180",
        "phones": [
            {
                "country": "55",
                "area": "79",
                "number": "999082488",
                "type": "MOBILE"
            }
        ]
    },
    "items": [
      {
        "reference_id": "01",
        "name": "servico studio nice hair",
        "quantity": 1,
        "unit_amount": value
      }
    ],
    "charges": [
        {
            "reference_id": "01",
            "description": "servico studio nice hair",
            "amount": {
                "value": value,
                "currency": "BRL"
            },
            "payment_method": {
                "type": "CREDIT_CARD",
                "installments": 1,
                "capture": true,
                "card": {
                    "encrypted": cardencrypted,
                    "store": false
                }
            }
        }
    ]
}
return data;
};

export default dataCard;