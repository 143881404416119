import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content:center;

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;

  .rowStyle {
    justify-content: center;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {

    .positionRow{
      position: absolute;
      bottom: 15px;
      justify-content: space-around;
      width: 100%;
    }

    .buttonStyle {
      width : 150px; 
    }

    .inputphone {
      height: 32px !important;
    }

    .sizeInput{
      width: 18rem;
      text-align: center;
    }

    #date {
      text-align: center;
    }

    .spanLetters {
      font-size: 20px;
      font-family: sans-serif; 
      padding: 10px;
      color: #a68c5b;
    }

    span {
      color: #a68c5b;
    }

    h1 {
      color: #a68c5b;
    }

    .ficolor {
      color: #a68c5b;
    }

    .textwidth {
      width: 20rem;
      padding-bottom: 10px;
      font-weight: bolder;
      table , tr , td ,  a{
        color: #a68c5b ;
      }

      .ant-table-content {
        overflow-y: scroll;
        max-height: 350px;
      }
    }
    
    h1 {
      margin: 5px 0px -20px;
    }

    .selectPaymentMode {
      width: 15rem;  
    }
    
  }
    @media only screen and (min-width: 375px) and (max-height: 667px) {

      .rowStyle {
        justify-content: center;
        margin-bottom: 0px;
      }

      .textwidth {
        padding-bottom: 0px;
        height: 16.5rem;
        font-weight: bolder;
        table , tr , td ,  a{
          color: #a68c5b ;
        }
        .ant-table-content {
          overflow-y: scroll;
          max-height: 18rem;
        }
      }
  }

  @media only screen and (min-width: 485px) and (max-width: 769px) {
    .sizeInput{
      width: 35rem;
    }
  }

  @media only screen and (min-width: 772px) and (max-width: 1025px) {
    .sizeInput{
      width: 40rem;
    }
  }

  @media only screen and (min-width: 1030px) and (max-width: 1200px) {
    .sizeInput{
      width: 50rem;
    }
  }

  @media only screen and (min-width: 1205px) {
    .sizeInput{
      width: 60rem;
    }
  }
`;

const apearFromLeft = keyframes`

  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation: ${apearFromLeft} 1s;
`;