import React, {
    createContext, useCallback, useState, useContext, useEffect
  } from 'react';
import { UseToast } from './Toast';
import { UseLanguage } from './Lang';
import api from '../services/api';
  
  
  interface LoginCredentials {
    username: string;
    password: string;
    lang: string;
  }
  
  interface AuthContextData {
    logged: string;
    signIn(data: LoginCredentials): Promise<void>;
    signOut(): void;
  }
  
  interface AuthState {
    token: string;
    user: string;
  }
  
  const AuthContext = createContext<AuthContextData>({} as AuthContextData);
  
  function clearStorage(): void {
    const keys = Object.keys(localStorage);
    for (let i = 0; i < keys.length; i++) {
      localStorage.removeItem(keys[i]);
    }
  }
  
  const AuthProvider = ({ children }: any) => {
    const { addToast } = UseToast();

    const { changeLanguage } = UseLanguage();
    const [data, setData] = useState<AuthState>(() => {
      const token = localStorage.getItem('@theagenda:token');
      const user = localStorage.getItem('@theagenda:user'!);
      if (token && user) {
        return { token , user};
      }
      return {} as AuthState;
    });
    
    useEffect(() => {
      setData({} as AuthState);
      localStorage.removeItem('@theagenda:token');
      localStorage.removeItem('@theagenda:user');
    }, []);
  
    const signIn = useCallback(async (dataLogin: LoginCredentials) => {
      const { username, password, lang } = dataLogin;
      try {
        await api.post("/v1/user/auth/login", 
          {
            username: username,
            password: password,
          }
        ).then((res) => {
          const { user, token: {token} } = res.data;
          localStorage.setItem('@theagenda:token', token);
          localStorage.setItem('@theagenda:user', JSON.stringify(user));
          setData({ token, user });
          changeLanguage(lang);
          addToast({
            type: 'success',
            title: 'Logado com sucesso...',
            description: '',
          });
        })
         
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Login ou Senha incorretos',
          description: '',
        });
      }
    }, [setData, addToast, changeLanguage]);
  
    const signOut = useCallback(() => {
      clearStorage();
      setData({} as AuthState);
    }, [setData]);
  
    return (
      <AuthContext.Provider
        value={{
          logged: data.user,
          signIn,
          signOut,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
  
  function UseAuth(): AuthContextData {
    const context = useContext(AuthContext);
  
    if (!context) {
      throw new Error('UseAuth must be used within a AuthProvider');
    }
    return context;
  }
  
export { AuthProvider, UseAuth };
