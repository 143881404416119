import { ToastProvider } from './Toast';
import { AuthProvider } from './Auth';
import { SidebarProvider } from './SideBar';
import { LanguageProvider } from './Lang';
import { IntlProvider } from 'react-intl';
import React from 'react';

interface Props {
    children?: React.ReactNode
}

const AppProvider: React.FC<Props> = ({ children }: Props) => (
    <>
        <ToastProvider>
            <LanguageProvider>
                <IntlProvider locale="pt">
                <AuthProvider>
                    <SidebarProvider>
                    {children}
                    </SidebarProvider>
                </AuthProvider>
                </IntlProvider>
            </LanguageProvider>
        </ToastProvider>
    </>
);

export default AppProvider;