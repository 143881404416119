import React, { useEffect, useState } from 'react';

import {
  Container,
  Content,
  AnimationContainer,
} from './styles';
import api from '../../services/api';
import { Card, Col, Divider, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Button from '../../components/Button';
import { FiSkipBack } from 'react-icons/fi';
import { UseLanguage } from '../../context/Lang';

interface DataSchedule {
  id: number;
  datescheduled: string;
  hourstart: string;
  nameclient: string;
  typeservice: string;
}

const Schedule: React.FC = () => {
  const navigate = useNavigate();
  const { t } = UseLanguage();
  const [data, setData] = useState<DataSchedule[]>([])

  useEffect(() => {
    try {
      const userSession = JSON.parse(localStorage.getItem('@theagenda:user')!);
      const today = new Date();
      api.post('/v1/schedule/list-by-user',
        {
          username: userSession.username,
          datescheduled: moment(today).format('YYYY-MM-DD')
        }
      ).then(res => {
        setData(res.data)
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <Container>
      <Content>
        <h1 className='subheader'>{t('my.scheduling')}</h1>
        <Divider />
        <AnimationContainer>
          <>
            {data.length !== 0 ? data.map(item => (
              <Row style={{ marginTop: '3em' }} key={item.id}>
                <Col sm={24} md={24} lg={24}>
                  <Row>
                    <Card className='cardStyle' key={item.id}>
                      <Row >
                        <Col sm={24} md={24} lg={24}>
                          <h1 className='styleh1'>
                            <p>
                              {moment(item.datescheduled).format('DD-MM-YYYY')}
                              <br/>
                              {item.hourstart}
                            </p>     
                            {item.typeservice}
                          </h1>
                        </Col>
                      </Row>
                    </Card>
                  </Row>
                </Col>
              </Row>
            ))
            :
            <Row>
                <Col sm={24} md={24} lg={24}>
                  <Row>
                    <Card className='noschedules'
                      onClick={() => navigate('create')}>
                      <Row>
                        <Col sm={24} md={24} lg={24}>
                          <h1 className='styleh1-noschedules'>
                            {t('no.schedules')}, 
                            <br /> 
                            {t('click.here.to.create')}
                          </h1>
                        </Col>
                      </Row>
                    </Card>
                  </Row>
                </Col>
              </Row>
            }
          </>
        </AnimationContainer> 
      </Content>
      <Button className='positionbutton' $bgcolor="#373435" $color="#ffffff" onClick={() => navigate('/')}>
          <FiSkipBack className='ficolor'/>
          <span>{t('back')}</span>
        </Button>
    </Container>
  );
};

export default Schedule;