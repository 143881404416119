import styled, { css } from 'styled-components';
import Tooltip from '../ToolTip';

interface ContainerPorps {
  $isfocused?: boolean;
  $isfilled?: boolean;
  $iserrored?: boolean;
}

export const Container = styled.div<ContainerPorps>`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  width: 100%;

  border: 2px solid #b5b5b5;
  color: #666360;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props => props.$iserrored
    && css`
      border-color: #c53030;
    `}

  ${props => props.$isfocused
    && css`
      color: #3f51b5;
      border-color: #3f51b5;
    `}

 ${props => props.$isfilled
    && css`
      border-color: #3f51b5;
      color: #3f51b5;
    `}


  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #000;
    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 8px;
    color: #a68c5b;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    input {
      font-size: 15px;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    height: 5rem;
    input {
      font-size: 15px;
      color: #a68c5b;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    height: 6rem;
    input {
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    height: 6rem;
    input {
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 1201px) {
    height: 6rem;
    input {
      font-size: 20px;
    }
  }   
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;