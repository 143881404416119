import React from 'react';

import {
  Container,
  Content,
  AnimationContainer
} from './styles';
import { Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UseLanguage } from '../../context/Lang';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { t } = UseLanguage();
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Row className='stylerow'>
            <Col sm={24} md={24} lg={24}>
              <Row>
                <Card 
                  className='cardStyle'
                  onClick={() => navigate('/schedule/create')}>
                  <Row>
                    <Col sm={24} md={24} lg={24}>
                      <h1 className='styleh1'>
                        {t('new.scheduling')}
                      </h1>
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <Card className='cardStyle'
                  onClick={() => navigate('/schedule')}>
                  <Row>
                    <Col sm={24} md={24} lg={24}>
                      <h1 className='styleh1'>
                        {t('my.scheduling')}
                      </h1>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Col>
          </Row>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Home;