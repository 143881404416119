import { useState } from "react";
import { Card, Col, DatePicker, DatePickerProps, Divider, Row } from "antd";
import { Container, Content } from "./styles";
import { AnimationContainer } from "./styles";
import { Dayjs } from "dayjs";
import { UseLanguage } from '../../../context/Lang';
import Button from "../../../components/Button";
import { FiFilter } from "react-icons/fi";
import api from "../../../services/api";
import moment from "moment";

interface DataSchedule {
    id: number;
    datescheduled: string;
    hourstart: string;
    nameclient: string;
    typeservice: string;
    phonenumber: string;
}

const FilterSchedule: React.FC = () => {
    const { t } = UseLanguage();
    const [dateChoiced, setDateChoiced] = useState<String | String[]>([])
    const [data, setData] = useState<DataSchedule[]>([])

    const onChange: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
        setDateChoiced(dateString)
    }

    const onFilter = async () => {
        await api.post('v1/schedule/list-by-date', {
            datescheduled: dateChoiced
        }).then(async (res) => {
            setData(res.data)
        })
    }
    return (
        <Container>
            <Content>
                <h1>{t('show.schedules')}</h1>
                <Divider />
                <>
                    <Row className='rowStyle'>
                        <Col sm={24} md={24} lg={24}>
                            <span className='span'>{t('choose.date')}</span>
                        </Col>
                    </Row>
                    <Row className='rowStyle'>
                        <Col sm={24} md={24} lg={24}>
                            <DatePicker
                                id='date'
                                className='sizeInput'
                                onChange={onChange}
                                inputMode="none"
                                onFocus={e => e.target.blur()}
                            />
                        </Col>
                    </Row>
                    <Row className='styleRowSchedule'>
                        <Col sm={24} md={24} lg={24}>
                            <Button className='buttonStyle' type='button' $bgcolor="#373435" $color="#ffffff" onClick={onFilter}>
                                <FiFilter className="ficolor"/>
                                <span>{t('filter')}</span>
                            </Button>
                        </Col>
                    </Row>
                </>
                <AnimationContainer>
                    <Divider />
                    <>
                        {data.length !== 0 ? data.map(item => (
                            <Row style={{ marginTop: '3em' }} key={item.id}>
                                <Col sm={24} md={24} lg={24}>
                                    <Row>
                                        <Card className='cardStyle' key={item.id}>
                                            <Row style={{ justifyContent: 'center' }}>
                                                <Col sm={24} md={24} lg={24}>  
                                                    <p className="tagp"> {t('name')}: {item.nameclient}</p>
                                                    <p className="tagp"> {t('date')}: {moment(item.datescheduled).format('DD-MM-YYYY')}</p>                                                   
                                                    <p className="tagp"> {t('time')}: {item.hourstart} </p>                                                   
                                                    <p className="tagp"> {t('service')}: {item.typeservice} </p>                         
                                                    <p className="tagp"> {t('phone')}: {item.phonenumber}</p>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col>
                            </Row>
                        ))
                            :
                            <Row>
                                <Col sm={24} md={24} lg={24}>
                                    <Row>
                                        <Card className='noschedules'>
                                            <Row>
                                                <Col sm={24} md={24} lg={24}>
                                                    <h1 className='tagp-noschedules'>
                                                        {t('no.schedules')}
                                                    </h1>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </>
                </AnimationContainer>
            </Content>
        </Container>
    )
}
export default FilterSchedule;