import React from 'react';
import Header from '../Header';
import Sidebar from '../SideBar';

const Template = () => (
  <>
    <Header />
    <Sidebar />
  </>
);

export default Template;