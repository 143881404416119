import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconBaseProps } from 'react-icons';

import { UseSidebar } from '../../context/SideBar';
import { UseLanguage } from '../../context/Lang';
import './style.css';

interface ItemData {
  id?: number;
  name: string;
  labelPt: string;
  labelEng: string,
  icon: React.ComponentType<IconBaseProps> | null;
  component: string | null;
  path: string | null;
  parent: number | null;
  items: ItemData;
}

interface SidebarItemData {
  depthStep: number;
  depth: number;
  item: ItemData
}

const SidebarItem: React.FC<SidebarItemData> = (props) => {
  const { depthStep, depth, item } = props;
  const {
    labelPt, labelEng, items, path, icon: Icon,
  } = item;
  const [collapsed, setCollapsed] = useState(false);

  const { showSidebar, setShowSidebar } = UseSidebar();
  const { language } = UseLanguage();
  const label = language === 'pt-br' ? labelPt : labelEng;
  function toggleCollapse() {
    setCollapsed((prevValue) => !prevValue);
  }

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? (
      <ExpandMoreIcon className="sidebar-item-expand-arrow" />

    ) : (
      <ExpandLessIcon
        className="sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded"
      />
    );
  }

  const listItemProps = (Array.isArray(items)) ? {
    onClick: toggleCollapse,
  } : {
    component: Link,
    onClick: (() => setShowSidebar(!showSidebar)),
    to: path,
    replace: true,
  };
  return (
    <>
      <ListItem {...listItemProps} button dense>
        <div style={{ paddingLeft: depth * depthStep }} className="sidebar-item-content">
          {Icon && <div className="sidebar-item-icon"><Icon size={20} /></div>}

          <div className="sidebar-item-text">
            {label}
          </div>
        </div>
        {expandIcon}
      </ListItem>
      <Collapse in={collapsed} timeout="auto">
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem) => (
              <React.Fragment key={`${subItem.name}`}>
                <SidebarItem
                  depth={depth + 1}
                  depthStep={depthStep}
                  item={subItem}
                />
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
};

export default SidebarItem;