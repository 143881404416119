import styled from 'styled-components';
import { shade } from 'polished';

interface ButtonProps {
  $bgcolor: string;
  $color: string;
}

export const Container = styled.button<ButtonProps>`
  background: ${(props) => props.$bgcolor};
  height: 32px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: ${(props) => props.$color};
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items:center;
  outline: none;

  transition: background 0.2s;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background: ${(props) => shade(0.2, props.$bgcolor)};
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    height: 3rem;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    height: 3rem;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    height: 4rem;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    height: 4rem;
  }
  @media only screen and (min-width: 1201px) {
    height: 5rem;
  }
`;