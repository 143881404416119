import React, { useState } from 'react';
import { FiLock, FiUser, FiSave, FiSkipBack } from 'react-icons/fi';

import * as Yup from 'yup';
import { useFormik, FormikProvider, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  AnimationContainer,
  Container,
  Content,
} from './styles';

import getValidationErrors from '../../utils/getValidadeErros';

import { UseToast } from '../../context/Toast';
import { UseLanguage } from '../../context/Lang';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import api from '../../services/api';
import logoImg from '../../assets/logostudio.jpeg';
import { Row } from 'antd';

const User: React.FC = () => {
  const { addToast } = UseToast();
  const [loading, setLoading] = useState(false);
  const { t } = UseLanguage();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      username: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      firstname: Yup.string().required('Nome obrigatório.'),
      lastname: Yup.string().required('Sobrenome obrigatório'),
      username: Yup.string().required('E-mail obrigatório.'),
      password: Yup.string().required('Senha obrigatória'),
    }),
    async onSubmit(values, { resetForm }) {
      setLoading(true);
      try {
        formik.setErrors({});
        await api.post('/v1/user/register', {
          firstname: values.firstname,
          lastname: values.lastname,
          username: values.username,
          password: values.password,
          role: 'USER'
        })
        addToast({
          type: 'success',
          title: 'Success',
          description: t('register.success'),
        });
        navigate('/')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formik.setErrors(errors);
          setTimeout(() => {
            setLoading(false);
            resetForm();
          }, 1000 * 2);
        }
        console.log(err);
        addToast({
          type: 'error',
          title: 'Error',
          description: t('something.is.wrong'),
        });
      }
      setLoading(false);
    },
  })

  return (
    <Container>
      <Loading display={loading} />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="studio-logo" width={225} height={150} />
          <FormikProvider
            value={formik}
          >
            <Form style={{ margin: 15 }}
              onSubmit={formik.handleSubmit}>
              <h1>{t('welcome')}</h1>
              <h2>{t('register.account')}</h2>
              <Input
                name="firstname"
                icon={FiUser}
                placeholder={t('name')}
                value={formik.values.firstname}
                onChange={formik.handleChange}
              />
              <Input
                name="lastname"
                icon={FiUser}
                placeholder={t('lastname')}
                value={formik.values.lastname}
                onChange={formik.handleChange}
              />
              <Input
                name="username"
                icon={FiUser}
                placeholder="E-mail"
                value={formik.values.username}
                onChange={formik.handleChange}
              />
              <Input
                name="password"
                icon={FiLock}
                placeholder={t('password')}
                value={formik.values.password}
                onChange={formik.handleChange}
              />   
              <Row className='positionRow'>
                <Button className="styleButton" $bgcolor="#373435" $color="#ffffff" onClick={() => navigate('/')}>
                  <FiSkipBack className="ficolor" />
                  <span>{t('back')}</span>
                </Button>
                <Button className="styleButton" type="submit" $bgcolor="#373435" $color="#ffffff">
                  <FiSave className="ficolor" />
                  <span>{t('create')}</span>
                </Button>
              </Row>
            </Form>
          </FormikProvider>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default User;