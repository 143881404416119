import styled from 'styled-components';

interface HeaderProps {
  bgcolor?: string;
}

export const Container = styled.header<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2em;
  background-color: ${(props) => props.bgcolor || '#373435;'};
  border-bottom: 1px solid lightgrey;
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    height: 5em;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    height: 6em;
  }
  @media only screen and (min-width: 1201px) {
    height: 8em;
  }
`;