import React from 'react';
import './style.css';
import SidebarTop from './SideBarTop';
import SidebarContent from './SideBarContent';
import { UseSidebar } from '../../context/SideBar';

const Sidebar = () => {
  const { showSidebar } = UseSidebar();
  const toggleSideBar = (showSidebar) ? 'sidebar-show' : 'sidebar-hide';
  const toggleOverlay = (showSidebar) ? 'page-overlay-show' : 'page-overlay-hide';
  return (
    <>
      <div
        className={`page-overlay ${toggleOverlay}`}
        role="button"
        aria-label="Page Overlay"
        tabIndex={0}
      />
      <div className={`sidebar scroll-style ${toggleSideBar}`}>
        <SidebarTop />
        <SidebarContent />
      </div>
    </>
  );
};

export default Sidebar;