import React, { useCallback, useEffect, useState } from 'react';

import { FiCopy, FiSave, FiSkipBack } from 'react-icons/fi';
import { Col, Divider, Image, Input, Popover, Row, Select, Space, Table } from 'antd';
import type { SelectProps, TableColumnsType } from 'antd';
import { DatePicker, DatePickerProps } from 'antd';
import { Dayjs } from 'dayjs';
import moment from 'moment';
import { Modal } from 'antd';

import {
  Container,
  Content,
  AnimationContainer
} from './styles';


import { useNavigate } from 'react-router-dom';

import api from '../../../services/api';
import { UseToast } from '../../../context/Toast';
import { UseLanguage } from '../../../context/Lang';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';

import dataCard from '../../../utils/reqcard';
import data from '../../../utils/dataType'
import { encryptCardPagSeguro } from '../../../utils/pagSeguroScript';

interface DataType {
  key: React.Key;
  hour: string;
}

const ScheduleCreate: React.FC = () => {
  const { addToast } = UseToast();
  const [loading, setLoading] = useState(false);
  const { t } = UseLanguage();
  const navigate = useNavigate();
  const options: SelectProps['options'] = [];
  const [optionServices, setOptionServices] = useState<any[]>();
  const [optionPayments, setOptionPayments] = useState<any[]>();
  const [serviceChooiced, setServiceChooiced] = useState('');
  const [timeStart, setTimeStart] = useState('');
  const [timeFinish, setTimeFinish] = useState('');
  const [dateChoiced, setDateChoiced] = useState<String | String[]>([]);
  const [hourChoiced, setHourChoiced] = useState('');
  const [phone, setPhone] = useState('');
  const userSession = JSON.parse(localStorage.getItem('@theagenda:user')!);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [choosepix, setChoosePix] = useState(false);
  const [choosecard, setChooseCard] = useState(false);
  const [numberCard, setNumberCard] = useState('');
  const [dateExp, setDateExp] = useState('');
  const [securityNumber, setSecurityNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [typePayment, setTypePayment] = useState('');
  const [qrcode, setQrcode] = useState('');
  const [textPix, setTextPix] = useState('');
  const [advancePay, setAdvancePay] = useState(0);
  const [openPopOver, setOpenPopOver] = useState(false);

  const showModal = () => {
    setOpen(true);
    setOptionPayments([
      { value: 'card', label: 'Cartao' },
      { value: 'pix', label: 'Pix' }
    ]);
  };

  const fetchData = useCallback(async () => {
    const data = await api.get('v1/type-service/list-all');
    data.data.map((item: any) => {
      options.push({
        value: String(item.nameservice),
        label: item.nameservice,
        price: item.valueservice,
      })
      return options
    })
    setOptionServices(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData().catch(console.error)
  }, [fetchData])

  const fetchDates = useCallback(async (date: any, service: string, hour: string) => {
    try {
      const datesAvailable = await api.post('v1/schedule/list-by-date-service', {
        datescheduled: date,
        typeservice: service,
        hour: hour
      })
      // eslint-disable-next-line
      datesAvailable.data.map((item: any) => {
        setTimeStart(item.hourstart)
        setTimeFinish(item.hourfinish)
      })
      // eslint-disable-next-line
      if (datesAvailable.data != '') {
        addToast({
          type: 'error',
          title: 'Desculpe, Horário Preenchido',
          description: 'Por Favor Escolha Outro Horário',
        })
      }
    } catch (e: any) {
      console.log('ops..', e)
    }
    // eslint-disable-next-line
  }, [])

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: 'hour',
      // eslint-disable-next-line
      render: (text: string) => <a>{text}</a>
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRow: DataType[]) => {
      const { hour } = selectedRow[0]
      setHourChoiced(hour)
      fetchDates(dateChoiced, serviceChooiced, hour)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.hour >= timeStart && record.hour <= timeFinish,
      hour: record.hour,
    }),
  };

  const onChange: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
    setDateChoiced(dateString)
  }
  const disabledDate = (currentDate: Dayjs) => {
    return currentDate.isBefore(moment().format("YYYY-MM-DD"), 'day');
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    if (typePayment === "card") {
      paymentCard().then(res => {
        if (res.data === 'PAID') {
          setConfirmLoading(false);
          setOpen(false);
          onSave();
        } else {
          addToast({
            type: 'error',
            title: 'Error',
            description: t('something.is.wrong'),
          });
          handleCancel();
        }
      })
        .catch(e => {
          addToast({
            type: 'error',
            title: 'Error',
            description: t('something.is.wrong'),
          });
          console.log(e)
          handleCancel();
        })
    } else if (typePayment === "pix") {
      setConfirmLoading(false);
      setOpen(false);
      onSave();
    }
  }
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setTypePayment('');
    setCardName('');
    setNumberCard('');
    setDateExp('');
    setSecurityNumber('');
    setQrcode('');
    setTextPix('');
    setOpen(false);
    setConfirmLoading(false);
  }
  const onSave = async () => {
    try {

      const timeservice = await api.post('v1/type-service/list-one-service', {
        nameservice: serviceChooiced
      })
      setLoading(true);
      await api.post('v1/schedule/create', {
        nameclient: userSession.firstname,
        datescheduled: dateChoiced,
        typeservice: serviceChooiced,
        hourstart: hourChoiced,
        hourfinish: String(Number(hourChoiced.slice(0, 2)) + Number(timeservice.data[0].timedurationservice) + ":00"),
        phonenumber: phone,
        username: userSession.username
      })
      addToast({
        type: 'success',
        title: 'Success',
        description: t('schedule.succes'),
      });
      setLoading(false);
      navigate('/');
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Error',
        description: t('something.is.wrong'),
      });
      setLoading(false);
      navigate('/');
      console.log('ops..', e)
    }
  }

  useEffect(() => {
    if (typePayment === 'pix') {
      setChoosePix(true);
      setChooseCard(false);
      paymentPix();
    } else if (typePayment === 'card') {
      setChoosePix(false);
      setChooseCard(true);
    } else {
      setChoosePix(false);
      setChooseCard(false);
    }
    // eslint-disable-next-line
  }, [typePayment])

  useEffect(() => {
    // eslint-disable-next-line
    const valuePayment = optionServices?.map(item => {
      if (item.value === serviceChooiced)
        setAdvancePay((Number(item.price) * 30) / 100)
      return item.price;
    })
    // eslint-disable-next-line
  }, [serviceChooiced])

  const paymentPix = async () => {
    await api.post('v1/requestapi_pagseguro/request-pix', { price: advancePay, service: serviceChooiced })
      .then(async res => {
        setQrcode(res.data[1])
        setTextPix(res.data[0])
      })
      .catch(err => console.log(err))
  }

  const paymentCard = async () => {
    const cardEncrypted = encryptCardPagSeguro({
      holder: cardName,
      number: numberCard,
      expMonth: dateExp.slice(0, 2),
      expYear: dateExp.slice(3, 7),
      securityCode: securityNumber
    }
    )
    const dataReturnedCard = dataCard(advancePay, cardEncrypted.encryptedCard);
    const res = await api.post('v1/requestapi_pagseguro/request-card', dataReturnedCard)
    return res;
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopOver(newOpen);
    setTimeout(() => {
      setOpenPopOver(!newOpen);
    }, 3000)
  };

  return (
    <Container>
      <Loading display={loading} />
      <Content>
        <AnimationContainer>
          <h1>{t('scheduling')}</h1>
          <Divider />
          <Space.Compact>
            <Col>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <span className='spanLetters'>{t("choose.service")}</span>
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <Select
                    id='service'
                    showSearch={false}
                    className='sizeInput'
                    options={optionServices}
                    value={serviceChooiced}
                    onChange={(e) => setServiceChooiced(e.valueOf())}
                  />
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <span className='spanLetters'>{t('choose.date')}</span>
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <DatePicker
                    id='date'
                    name='date'
                    className='sizeInput'
                    onChange={onChange}
                    disabledDate={disabledDate}
                    onFocus={e => e.target.blur()}
                  />
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <span className='spanLetters'>{t('phone')}</span>
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <Input
                    className='sizeInput'
                    name='phone'
                    type='tel'
                    maxLength={11}
                    value={phone}
                    onChange={e => setPhone(e.currentTarget.value)}
                    placeholder='Cel'
                  />
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <span className='spanLetters'>{t('choose.time')}</span>
                </Col>
              </Row>
              <Row className='paddingBottom'>
                <Col sm={24} md={24} lg={24}>
                  <Table
                    className='textwidth'
                    rowSelection={{
                      type: 'radio',
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Col>
          </Space.Compact>
        </AnimationContainer>
        <Row className='positionRow'>
          <Button className='buttonStyle' $bgcolor="#373435" $color="#ffffff" onClick={() => navigate('/')}>
            <FiSkipBack className='ficolor' />
            <span>{t('back')}</span>
          </Button>
          <Button className='buttonStyle' type='button' $bgcolor="#373435" $color="#ffffff" onClick={showModal}>
            <FiSave className='ficolor' />
            <span>{t('confirm')}</span>
          </Button>
        </Row>
      </Content>
      <Modal
        title={t('schedule.reservation')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        style={{ width: '22em' }}
      >
        <Row style={{ justifyContent: 'center' }}>
          <span>{t('method.payment')}</span>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Select
            id='payment'
            showSearch={false}
            style={{ width: '10rem', textAlign: 'center' }}
            options={optionPayments}
            value={typePayment}
            onChange={(e) => setTypePayment(e.valueOf())}
          />
        </Row>
        {
          choosepix ?
            <>
              <Row style={{ justifyContent: 'center', marginTop: '5px' }}>
                <span style={{ fontFamily: "sans-serif", fontSize: "12px", fontWeight: "bold", textAlign: "justify" }}>
                  {t('scan.qrcode')} &nbsp;
                  <FiCopy style={{ color: "#a68c5b" }} /> &nbsp;
                  {t('bellow')}
                  <br />
                  {t('confirm.ok')}
                </span>
                <Col sm={24} md={24} lg={24}>
                  <Image
                    src={`data:image/png;base64,${qrcode}`}
                    alt='QRcodeimg'
                    style={{ width: '12rem' }}
                    loading='lazy'
                    id='testeimg'
                  />
                </Col>
              </Row>
              <Row style={{ justifyContent: 'center', marginTop: '5px' }}>
                <span style={{ fontFamily: "sans-serif", fontSize: "12px", fontWeight: "bold", textAlign: "justify" }}>Valor: R$ {advancePay},00</span>
              </Row>
              <Row style={{ justifyContent: 'center' }}>
                <Col sm={18} md={18} lg={18}>
                  <Input
                    value={textPix}
                    disabled
                  />
                </Col>
                <Col>
                  <Popover
                    content={t('copied.code')}
                    trigger="click"
                    placement="right"
                    open={openPopOver}
                    onOpenChange={handleOpenChange}>
                    <Button
                      type="button"
                      $bgcolor="#ebf0ec"
                      $color="#ffffff"
                      style={{ height: "2.5em", width: "3em", marginLeft: "5px", padding: "0px" }}
                      onClick={() => navigator.clipboard.writeText(textPix)}
                    >
                      <FiCopy style={{ color: "#a68c5b", marginRight: "0px" }} />
                    </Button>
                  </Popover>
                </Col>
              </Row>
            </>
            : null
        }
        {
          choosecard ?
            <Row>
              <Col sm={24} md={24} lg={24}>
                <Row style={{ justifyContent: 'center', marginTop: '5px' }}>
                  <span style={{ fontFamily: "sans-serif", fontSize: "12px", fontWeight: "bold", textAlign: "justify" }}>Valor: R$ {advancePay},00</span>
                </Row>
                <Row>
                  <Col sm={24} md={24} lg={24} style={{ width: '26em' }}>
                    <Row>
                      <span>{t('card.number')}</span>
                    </Row>
                    <Row>
                      <Input
                        name='cardnumber'
                        value={numberCard}
                        onChange={e => setNumberCard(e.currentTarget.value)}
                        placeholder='0000 0000 0000 0000'
                      />
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} md={24} lg={24} style={{ width: '26em' }}>
                    <Row style={{ justifyContent: 'space-between' }}>
                      <Col sm={12} md={12} lg={12}>
                        <Row>
                          <span>{t('expiration.date')}</span>
                        </Row>
                        <Row>
                          <Input
                            style={{ width: '10em' }}
                            name='dateexp'
                            value={dateExp}
                            onChange={e => setDateExp(e.currentTarget.value)}
                            placeholder='00/0000'
                          />
                        </Row>
                      </Col>
                      <Col sm={12} md={12} lg={12}>
                        <Row>
                          <span>{t('security.code')}</span>
                        </Row>
                        <Row>
                          <Input
                            style={{ width: '10em' }}
                            type='number'
                            name='securitycode'
                            maxLength={3}
                            value={securityNumber}
                            onChange={e => setSecurityNumber(e.currentTarget.value)}
                            placeholder='123'
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} md={24} lg={24} style={{ width: '26em' }}>
                    <Row>
                      <span>{t('card.name')}</span>
                    </Row>
                    <Row>
                      <Input
                        name='customername'
                        value={cardName}
                        onChange={e => setCardName(e.currentTarget.value)}
                        placeholder={t('customer.name')}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            : null
        }
      </Modal>
    </Container>
  );
};

export default ScheduleCreate;
