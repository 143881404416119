import { Outlet } from "react-router-dom"
import { ConfigProvider } from "antd";

const Layout = () => {
    return (
        <main className="App">
            <ConfigProvider theme={{hashed: false}}>
                <Outlet />
            </ConfigProvider>
        </main>
    )
}

export default Layout;