import React, { useState } from 'react';
import * as Yup from 'yup';

import {
  Container,
  Content,
  AnimationContainer
} from './styles';
import Loading from '../../components/Loading';
import { Form, FormikProvider, useFormik } from 'formik';
import { FiMail, FiSend, FiSkipBack } from 'react-icons/fi';
import { UseToast } from '../../context/Toast';
import { UseLanguage } from '../../context/Lang';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidadeErros';
import Input from '../../components/Input';
import Button from '../../components/Button';

import logoImg from '../../assets/logostudio.jpeg';


const Recovery: React.FC = () => {
  const { addToast } = UseToast();
  const [loading, setLoading] = useState(false);
  const { t } = UseLanguage();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('E-mail obrigatório.')
    }),
    async onSubmit(values, {resetForm}) {
      setLoading(true);
      try {
        formik.setErrors({});
        await api.post('/v1/user/forgot',{
          email: values.email
        })
        addToast({
          type: 'success',
          title: 'Enviado com sucesso...',
          description: 'Cheque sua caixa de entrada!',
        });
        navigate('/')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formik.setErrors(errors);
          setTimeout(() => {
            setLoading(false);
            resetForm();
          }, 1000 * 2);
        }
        console.log(err);
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Opss...',
        });
      }
      setLoading(false);
    },
  })

  return (
    <Container>
      <Loading display={loading} />
      <Content>
        <AnimationContainer>
        <img src={logoImg} alt="studio-logo" width={225} height={150} />
        <FormikProvider 
          value={formik}
          >
          <Form style={{ margin: 15 }} 
           onSubmit={formik.handleSubmit}>
              <h1 style={{ color: '#a68c5b' }}>{t('welcome')}</h1>
              <h2 style={{ color: '#a68c5b' }}>{t('recovery.password')}</h2>
              <Input 
                name="email" 
                icon={FiMail} 
                type="email"
                placeholder="E-Mail" 
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <Button type="submit" $bgcolor="#373435" $color="#ffffff">
                <FiSend style={{ color: '#a68c5b' }}/>
                <span style={{ color: '#a68c5b' }}>{t('send')}</span>
              </Button>
              <Button type="button" onClick={() => navigate('/')} $bgcolor="#373435" $color="#ffffff">
                <FiSkipBack style={{ color: '#a68c5b' }}/>
                <span style={{ color: '#a68c5b' }}>{t('back')}</span>
              </Button>
            </Form>
          </FormikProvider>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Recovery;