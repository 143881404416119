import { UseAuth } from '../context/Auth';
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';

const RoutesConfig = () => {
  const { logged } = UseAuth();
  const loggedin = !!logged;
  
  return (
    loggedin ? <AppRoutes /> : <AuthRoutes />
  );
};

export default RoutesConfig;