import { FiArchive, FiHome, FiList } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons';

interface Menu {
  id: number;
  name: string;
  labelPt: string;
  labelEng: string;
  icon: React.ComponentType<IconBaseProps> | null;
  component: string | null;
  path: string | null;
  parent: number | null;
}

const menu: Menu[] = [
  {
    id: 1,
    name: 'Home',
    labelPt: 'Inicio',
    labelEng: 'Home',
    icon: FiHome,
    component: 'Home',
    path: '/',
    parent: null,
  },
  {
    id: 2,
    name: 'agendar',
    labelPt: 'Agendar',
    labelEng: 'Schedule',
    icon: FiArchive,
    component: 'Schedule',
    path: '/schedule',
    parent: null,
  },
  {
    id: 3,
    name: 'listar_agendamentos',
    labelPt: 'Listar Agendamentos',
    labelEng: 'Show Schedules',
    icon: FiList,
    component: 'FilterSchedule',
    path: '/schedule/filterschedule',
    parent: null,
  },
];
export default menu;