import React, {
    InputHTMLAttributes,
    useState,
    useRef,
    useCallback,
  } from 'react';
  import { IconBaseProps } from 'react-icons';
  import { FiAlertCircle } from 'react-icons/fi';
  import { useField, Field } from 'formik';
  import { Container, Error } from './styles';
  
  interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon: React.ComponentType<IconBaseProps>;
  }
  
  const Input: React.FC<InputProps> = ({ name, icon: Icon, ...rest }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [field, meta] = useField(name)
  
    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
  
      setIsFilled(!!inputRef.current?.value);
    }, []);
    const handleInputFocus = useCallback(() => {
      setIsFocused(true);
    }, []);
    return (
      <Container $iserrored={!!meta.error} $isfocused={isFocused} $isfilled={isFilled}>
        {Icon && <Icon size={20} />}
        <Field
          {...field}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={field.value}
          // ref={inputRef}
          {...rest}
        />
        {meta.error && (
          <Error title={meta.error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    );
  };
  
  export default Input;