import axios from 'axios';

const api = axios.create({
  // baseURL: process.env.REACT_APP_API_URL
  baseURL: 'https://api.studionicehair.com',
  // baseURL: 'http://127.0.0.1:8080',
  
});

api.interceptors.request.use((config: any) => {
  const t = config;
  const token = getToken();
  t.headers = {"Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'};
  if (token) {
    t.headers.authorization = `Bearer ${token}`;
    t.headers.user = getUser();
  }
  return t;
},
(error) => Promise.reject(error));

function getToken() {
  return localStorage.getItem('@theagenda:token');
}

function getUser() {
  return localStorage.getItem('@theagenda:user');
}

export default api;
