import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Login from '../pages/Login';
import User from '../pages/User';
import Recovery from '../pages/Recovery';
import ChangePassword from '../pages/Recovery/ChangePassword';

const AuthRoutes = () => (
  <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true}}>
    <Routes>
      <Route path='/' element={<Login/>}/>
      <Route path='/register' element={<User />}/>
      <Route path='/forgot' element={<Recovery />}/>
      <Route path='/changepassword' element={<ChangePassword />}/>
    </Routes>
  </BrowserRouter>
);
export default AuthRoutes;