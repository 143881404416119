import { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

type HtmlButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
interface ButtonProps extends HtmlButtonProps {
  $bgcolor: string;
  $color: string
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <Container {...rest}>
    {children}
  </Container>
);

export default Button;