interface DataType {
    key: React.Key;
    hour: string;
}

const data: DataType[] = [
    {
      key: '0',
      hour: '09:00'
    },
    {
      key: '1',
      hour: '10:00'
    },
    {
      key: '2',
      hour: '11:00'
    },
    {
      key: '3',
      hour: '12:00'
    },
    {
      key: '4',
      hour: '13:00'
    },
    {
      key: '5',
      hour: '14:00'
    },
    {
      key: '6',
      hour: '15:00'
    },
    {
      key: '7',
      hour: '16:00'
    },
    {
      key: '8',
      hour: '17:00'
    },
    {
      key: '9',
      hour: '18:00'
    },
    {
      key: '10',
      hour: '19:00'
    },
    {
      key: '11',
      hour: '20:00'
    },
    {
      key: '12',
      hour: '21:00'
    },
    {
      key: '13',
      hour: '22:00'
    },
  ];

export default data;