import React from 'react';
import './styles.ts';
import { FiAlignJustify } from 'react-icons/fi';
import { UseSidebar } from '../../context/SideBar';

import { Container } from './styles';

interface PropsData {
  color: string;
  size: number;
}

const BurguerButton = ({ color, size }: PropsData) => {
  const { showSidebar, setShowSidebar } = UseSidebar();
  return (
    <Container
      className="burguer-button"
      onClick={() => setShowSidebar(!showSidebar)}
    >
      <FiAlignJustify
        style={{
          color,
        }}
        size={size}
      />
    </Container>
  );
};

export default BurguerButton;