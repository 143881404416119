import styled, { keyframes } from 'styled-components';

// import signInBackgroundImg from '../../assets/background.jpg';

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content:center
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
`;

/* export const ImageBackground = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
`; */

const apearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation: ${apearFromLeft} 1s;
  
  @media only screen and (min-width: 320px) and (max-width: 480px) {
      .cardStyle {
        margin-top: 15px;
        padding: 0px;
        width: 300px;
        height: 150px;
        text-align: center;
        background-color: #373435;
        cursor: pointer;
      }
      .styleh1 {
        font-family: sansSerif; 
        font-size: 35px;
        color: #a68c5b;
      }
    }
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      width: 30rem;
       .cardStyle {
        padding: 5px;
        width: 450px;
        height: 200px;
        text-align: center;
        margin-top: 5rem; 
        background-color: #373435;
        cursor: pointer;
      }
      .styleh1 {
        margin-top: 2.5rem;
        font-family: sansSerif; 
        font-size: 40px;
        color: #a68c5b;
        margin-left: 1.7rem;
      }
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      .cardStyle {
        padding: 5px;
        width: 600px;
        height: 250px;
        text-align: center;
        margin-top: 5rem; 
        background-color: #373435;
        cursor: pointer;
      }
      .styleh1 {
        margin-top: 3.5rem;
        font-family: sansSerif; 
        font-size: 50px;
        color: #a68c5b;
        margin-left: 1.7rem;
      } 
    }
    @media only screen and (min-width: 1025px) and (max-width: 1200px) {
      .cardStyle {
        padding: 5px;
        width: 700px;
        height: 300px;
        text-align: center;
        margin-top: 5rem; 
        background-color: #373435;
        cursor: pointer;
      }
      .styleh1 {
        margin-top: 3.5rem;
        font-family: sansSerif; 
        font-size: 60px;
        color: #a68c5b;
        margin-left: 1.7rem;
      } 
    }
    @media only screen and (min-width: 1201px) {
      .cardStyle {
        width: 700px;
        height: 300px;
        text-align: center; 
        background-color: #373435;
        cursor: pointer;
      }
      .styleh1 {
        margin-top: 4rem;
        font-family: sansSerif; 
        font-size: 70px;
        color: #a68c5b;
      } 
      .stylerow {
        margin-top: 2em;
      }
    }
`;