import { useTransition } from '@react-spring/web';

import { Container } from './styles';
import Toast from './Toast';
import { ToastMessageData } from '../../context/Toast';

interface ToastContainerData {
  messages: ToastMessageData[];
}

const ToastContainer: React.FC<ToastContainerData> = ({ messages }) => {

  const messagesWithTransitions = useTransition(
    messages,
    {
      from: { right: '-120%', opacity: 0 },
      enter: { right: '0%', opacity: 1 },
      leave: { right: '-120%', opacity: 0 },
    },
  );

  return (
    <Container>
      {messagesWithTransitions((item, key, props) => (
        <Toast toast={key} style={props} />
      ))}
    </Container>
  );
};

export default ToastContainer;