import React from 'react';
import { Container } from './styles';
import { useLocation } from 'react-router-dom';

const NoMatch: React.FC = () => {
  const location = useLocation()
  return (
    <Container>
      <div className="notfound">
        <div className="notfound-404">
          <h3>
            Oooooops! Page
            {' '}
            <span className="pagetitle">{location.pathname}</span>
            {' '}
            not found
          </h3>
          <h1>
            <span>4</span>
            <span>0</span>
            <span>4</span>
          </h1>
        </div>
      </div>
    </Container>
  );
};

export default NoMatch;