import React, {
    createContext, useCallback, useContext, useState, ReactNode
  } from 'react';
  import { v4 as uuid } from 'uuid';
  import ToastContainer from '../components/ToastContainer';
  
  export interface ToastMessageData {
    id: string;
    title: string;
    type?: 'success' | 'error' | 'info';
    description?: string;
  }
  
  interface ToastContextData {
    addToast(message: Omit<ToastMessageData, 'id'>): void;
    removeToast(id: string): void;
  }

  interface Props {
    children?: ReactNode
  }

  const ToastContext = createContext<ToastContextData>({} as ToastContextData);
  
  const ToastProvider: React.FC<Props> = ( { children }: Props ) => {
    
    const [messages, setMessages] = useState<ToastMessageData[]>([]);

    const addToast = useCallback(({ type, title, description }: Omit<ToastMessageData, 'id'>) => {
      
      const id = uuid();

      const toast = {
        id,
        type,
        title,
        description,
      };

      setMessages((state) => [...state, toast]);
    }, []);

    const removeToast = useCallback((id: string) => {
      setMessages((state) => state.filter((message) => message.id !== id));
    }, []);

    return (
      <ToastContext.Provider
        value={{
          addToast,
          removeToast,
        }}
      >
        {children}
        <ToastContainer messages={messages} />
      </ToastContext.Provider>
    );
  };
  
  function UseToast(): ToastContextData {
    const context = useContext(ToastContext);

    if (!context) {
      throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
  }
  
  export { ToastProvider, UseToast };