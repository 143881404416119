import React, {useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from './styles';

import { UseAuth } from '../../context/Auth';
import { UseToast } from '../../context/Toast';
import { UseSidebar } from '../../context/SideBar';
import { FiLogOut } from 'react-icons/fi';

const LogOut = () => {
  const navigate = useNavigate();
  const { addToast } = UseToast();
  const { signOut } = UseAuth();
  const { setShowSidebar } = UseSidebar();
  const logOut = useCallback(() => {
    addToast({
      type: 'info',
      title: 'Deslogado com sucesso...',
      description: '',
    });
    signOut();
    setShowSidebar(false);
    navigate('/');
  }, [signOut, addToast, setShowSidebar, navigate]);
  return (
    <Container>
      <Button type="button" className="button_exit" onClick={logOut}><FiLogOut /></Button>
    </Container>
  );
};

export default LogOut;